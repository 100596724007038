import React from 'react'
import { graphql } from "gatsby"
import MothButtons from '../components/MothButtons'
import CV from '../images/cv_opt.jpg'
import Img from "gatsby-image"
import SEO from '../components/seo'
import "./layout.css"

export default function CurriculumVitae({ data }) {
        const sources = [
            data.wideCV.childImageSharp.fluid,
            {
              ...data.longCV.childImageSharp.fluid,
              media: `(max-width: 720px)`,
            },
          ]

    return (
        <>
        <SEO title="Curriculum Vitae" />

        <div className="cv-content-wrap">
              <MothButtons size="small" order={[
                    {to: "/home", label: "Home"},
                    {to: "/portfolio", label:  "Portfolio"},
                    {to: "/links", label:  "Links"},
                    {to: "/contact", label:  "Contact"},
                    ]}/>
            <div className="cv-img-wrap" >
                <Img fluid={sources} />
            </div>
        </div>
        </>
    )
}

export const query = graphql`
query {
    wideCV: file(relativePath: { eq: "cv_opt.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
            }
            }
        },
        longCV: file(relativePath: { eq: "longcv.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
`
